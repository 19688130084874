<script>
export default {
  name: "IndicatorDisplay",
  props: {
    indicator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      animatedValue: 0,
    };
  },
  computed: {
    isPercentage() {
      return this.indicator.type === "perc";
    },
    displayValue() {
      if (this.isPercentage) {
        return `${this.animatedValue.toFixed(this.indicator.decimals)}%`;
      }
      return `${this.animatedValue.toFixed(this.indicator.decimals)} ${this
        .indicator.unit || ""}`;
    },
    progressValue() {
      if (this.isPercentage) {
        return this.animatedValue;
      }
      if (this.indicator.target && this.indicator.sensitivity) {
        const diff = Math.abs(this.indicator.value - this.indicator.target);
        return Math.max(0, 100 - (diff / this.indicator.sensitivity) * 100);
      }
      return 0;
    },
    progressColor() {
      const value = this.progressValue;
      if (value >= 75) {
        return "#4caf50"; // Verde
      } else if (value >= 50) {
        return "#f1c40f"; // Giallo
      } else {
        return "#e74c3c"; // Rosso
      }
    },
  },
  mounted() {
    if (this.isPercentage) {
      this.animateValue(
        0,
        (this.indicator.value / this.indicator.tot) * 100,
        1000
      );
    } else {
      this.animateValue(0, this.indicator.value, 1000);
    }
  },
  methods: {
    animateValue(start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.animatedValue = progress * (end - start) + start;
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<template>
  <div class="indicator-display">
    <h3>{{ indicator.title }}</h3>
    <p class="value">{{ displayValue }}</p>
    <div class="progress-bar" v-if="isPercentage">
      <div
        class="progress"
        :style="{ width: progressValue + '%', backgroundColor: progressColor }"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.indicator-display {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  min-height: 110px;

  h3 {
    margin: 0 0 0.5rem;
    font-size: 1.1rem;
    color: #333;
  }

  .value {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 1rem;
    color: #555;
  }

  .progress-bar {
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;

    .progress {
      height: 100%;
      transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
  }
}
</style>
