<script>
import AxiosService from "@/axiosServices/AxiosService";
import IndicatorDisplay from "../components/DashBoard/IndicatorDisplay.vue";
import IndicatorDisplay2 from "../components/DashBoard/IndicatorDisplay2.vue";
import IndicatorDisplay3 from "../components/DashBoard/IndicatorDisplay3.vue";
import IndicatorDisplay4 from "../components/DashBoard/IndicatorDisplay4.vue";
import LineGraph from "../components/DashBoard/LineGraph.vue";
import BarChart from "../components/DashBoard/BarChart.vue";
import BubbleChart from "../components/DashBoard/BubbleChart.vue";
import RadarChart from "../components/DashBoard/RadarChart";
import CombinedChart from "../components/DashBoard/CombinedChart.vue";
export default {
  name: "DashboardStats",
  components: {
    IndicatorDisplay,
    IndicatorDisplay2,
    IndicatorDisplay3,
    IndicatorDisplay4,
    LineGraph,
    BarChart,
    BubbleChart,
    RadarChart,
    CombinedChart,
  },
  service: null,
  created() {
    this.service = new AxiosService("Dashboard");
  },
  data() {
    return {
      linechartDataset: [
        {
          label: "Dataset 1",
          data: [10, 20, 30, 25, 35, 45],
        },
        {
          label: "Dataset 2",
          data: [15, 25, 35, 30, 40, 50],
        },
        // Add more datasets as needed
      ],
      bubbleDatasets: [
        {
          label: "Data Set 1",
          data: [
            { x: 10, y: 20, r: 15 },
            { x: 30, y: -40, r: -25 },
            { x: 50, y: -60, r: 20 },
            { x: -70, y: 80, r: 10 },
            { x: 90, y: -100, r: 5 },
            { x: 110, y: -120, r: 10 },
            { x: -130, y: 140, r: -15 },
            { x: 150, y: 160, r: -20 },
            { x: 170, y: -180, r: -25 },
            { x: -190, y: 200, r: 30 },
            { x: 210, y: 220, r: 35 },
          ],
        },
        {
          label: "Data Set 2",
          data: [
            { x: 15, y: 25, r: 10 },
            { x: 35, y: 45, r: 30 },
            { x: 55, y: -65, r: 15 },
            { x: 75, y: 85, r: 5 },
            { x: 95, y: 105, r: -10 },
            { x: 115, y: 125, r: 15 },
            { x: 135, y: 145, r: 20 },
            { x: 155, y: -165, r: 25 },
            { x: -175, y: 185, r: 30 },
            { x: -195, y: -205, r: 35 },
            { x: 215, y: 225, r: 40 },
          ],
        },
      ],
      datasets: [
        {
          label: "Dataset 1",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Dataset 2",
          data: [28, -48, 40, -19, -86, 27, -90],
          fill: false,
          borderColor: "rgb(255, 99, 132)",
          tension: 0.1,
        },
      ],
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      stats: {
        boxes: [
          {
            title: "Perc Login",
            value: 360,
            tot: 400,
            type: "perc",
            decimals: 2,
          },
          {
            title: "Media accessi",
            value: 4,
            type: "number",
            unit: "accessi",
            decimals: 1,
          },
          {
            title: "Perc Login",
            value: 260,
            tot: 400,
            type: "perc",
            decimals: 4,
          },
          {
            title: "Perc Login",
            value: 160,
            tot: 400,
            type: "perc",
          },
          {
            title: "Media durata sessione",
            value: 6,
            type: "number",
            unit: "minuti",
          },
          {
            title: "Media durata sessione",
            value: 10,
            type: "number",
            unit: "minuti",
          },
        ],
      },
    };
  },
};
</script>

<template>
  <div class="content-wrapper ">
    <h1>Dashboard</h1>

    <b-container>
      <b-row class="justify-content-between">
        <b-col v-for="(box, index) in stats.boxes" :key="index" class="mt-5">
          <indicator-display :indicator="box"></indicator-display>
        </b-col>
      </b-row>

      <b-row class=" justify-content-between">
        <b-col v-for="(box, index) in stats.boxes" :key="index" class="mt-5">
          <indicator-display-2 :indicator="box"></indicator-display-2>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col v-for="(box, index) in stats.boxes" :key="index" class="mt-5">
          <indicator-display-3 :indicator="box"></indicator-display-3>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col v-for="(box, index) in stats.boxes" :key="index" class="mt-5">
          <indicator-display-4 :indicator="box"></indicator-display-4>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col
          class="mt-5 
        "
        >
          <b-card style="height: 500px;">
            <line-graph :datasets="datasets" :labels="labels"></line-graph>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col
          class="mt-5 
        "
        >
          <b-card style="height: 500px;">
            <bar-chart :datasets="datasets" :labels="labels"></bar-chart>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col
          class="mt-5 
        "
        >
          <b-card style="height: 500px;">
            <bubble-chart
              :datasets="bubbleDatasets"
              :labels="labels"
            ></bubble-chart>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col
          class="mt-5 
        "
        >
          <b-card style="height: 500px;">
            <radar-chart :datasets="datasets" :labels="labels"></radar-chart>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="justify-content-between">
        <b-col
          class="mt-5 
        "
        >
          <b-card style="height: 500px;">
            <combined-chart
              :datasets="linechartDataset"
              :labels="labels"
            ></combined-chart>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
