<template>
  <div class="indicator-display">
    <h2>{{ indicator.title }}</h2>
    <div v-if="indicator.type === 'perc'" class="progress-bar-container">
      <div class="progress-bar" :style="progressBarStyle"></div>
      <span class="progress-value">{{ formattedValue }}%</span>
    </div>
    <div v-else class="number-display">
      <span>{{ formattedValue }} {{ indicator.unit }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndicatorDisplay4",
  props: {
    indicator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  computed: {
    formattedValue() {
      if (this.indicator.decimals !== undefined) {
        return this.indicator.value.toFixed(this.indicator.decimals);
      }
      return this.indicator.value;
    },
    progressBarStyle() {
      return {
        width: this.progress + "%",
        background: this.gradientColor,
        transition: "width 1s ease-in-out",
      };
    },
    gradientColor() {
      if (this.progress < 50) {
        return "linear-gradient(90deg, #FF4E50 0%, #FF4E50 100%)"; // Red gradient
      } else if (this.progress < 75) {
        return "linear-gradient(90deg, #FFED3B 0%, #FFED3B 100%)"; // Yellow gradient
      } else {
        return "linear-gradient(90deg, #4CAF50 0%, #4CAF50 100%)"; // Green gradient
      }
    },
  },
  mounted() {
    if (this.indicator.type === "perc") {
      this.animateProgress();
    }
  },
  methods: {
    animateProgress() {
      const targetValue = (this.indicator.value / this.indicator.tot) * 100;
      let start = 0;
      const duration = 1000;
      const increment = targetValue / (duration / 16);

      const animate = () => {
        if (start < targetValue) {
          start += increment;
          this.progress = start;
          requestAnimationFrame(animate);
        } else {
          this.progress = targetValue;
        }
      };
      animate();
    },
  },
};
</script>

<style scoped>
.indicator-display {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  border-radius: 15px;
  background: #fff;
  text-align: center;
  max-width: 300px;
  margin: 20px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.indicator-display:hover {
  transform: scale(1.05);
}

.indicator-display h2 {
  font-size: 1.4em;
  margin-bottom: 15px;
  color: #333;
}

.progress-bar-container {
  position: relative;
  height: 20px;
  background: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar {
  height: 100%;
  transition: width 1s ease-in-out, background 1s;
}

.progress-value {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #fff;
  font-weight: bold;
}

.number-display {
  font-size: 1.2em;
  color: #333;
}

.indicator-display span {
  font-size: 1.2em;
  color: #333;
}
</style>
