<script>
export default {
  name: "IndicatorDisplay",
  props: {
    indicator: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayedValue: 0,
    };
  },
  computed: {
    percentageValue() {
      if (this.indicator.type === "perc") {
        return (this.indicator.value / this.indicator.tot) * 100;
      }
      return null;
    },
    formattedValue() {
      if (this.indicator.type === "perc") {
        return this.percentageValue.toFixed(this.indicator.decimals) + "%";
      }
      return (
        this.indicator.value +
        (this.indicator.unit ? " " + this.indicator.unit : "")
      );
    },
    valueClass() {
      if (this.indicator.type === "perc") {
        return "";
      }
      return "";
    },
    progressBarClass() {
      if (this.indicator.type === "perc") {
        if (this.displayedValue >= 75) {
          return "high";
        } else if (this.displayedValue >= 50) {
          return "medium";
        } else {
          return "low";
        }
      }
      return "";
    },
  },
  mounted() {
    if (this.indicator.type === "perc") {
      this.animateValue(0, this.percentageValue, 1000);
    } else {
      this.animateValue(0, this.indicator.value, 1000);
    }
  },
  methods: {
    animateValue(start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        this.displayedValue = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<template>
  <div class="indicator-display">
    <h2>{{ indicator.title }}</h2>
    <div class="value" :class="[indicator.type, valueClass]">
      {{
        indicator.type === "perc"
          ? displayedValue.toFixed(this.indicator.decimals) + "%"
          : displayedValue.toFixed(this.indicator.decimals) +
            (indicator.unit ? " " + indicator.unit : "")
      }}
    </div>
    <div v-if="indicator.type === 'perc'" class="progress-bar">
      <div
        class="progress"
        :class="progressBarClass"
        :style="{ width: displayedValue + '%' }"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.indicator-display {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
  font-family: Arial, sans-serif;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
  }

  .value {
    margin: 10px 0;
    font-size: 2rem;
    font-weight: bold;
  }

  .value.perc {
    color: #333;
  }

  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #e9ecef;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;

    .progress {
      height: 100%;
      border-radius: 10px;
      transition: width 0.5s ease;
    }

    .progress.high {
      background-color: #28a745; // Green
    }

    .progress.medium {
      background-color: #ffc107; // Yellow
    }

    .progress.low {
      background-color: #dc3545; // Red
    }
  }
}
</style>
